import React from 'react';
import { Oval } from 'react-loader-spinner';
import '../../UI-style/Global/loader.css';

function Loader() {
  return (
    <div className='loader'>
      <Oval
        visible={true}
        height="60"
        width="60"
        color="#EF5B23"
        ariaLabel="oval-loading"
        secondaryColor="black"
      />
    </div>
  )
}

export default Loader