import React, { Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Loader from './Common/Loader';

const Index = lazy(() => import('./Index'));
const Services = lazy(() => import('./Services'));
const House = lazy(() => import('./House'));
const CommercialDelivery = lazy(() => import('./CommercialDelivery'));
const Furniture = lazy(() => import('./Furniture'));
const B2B = lazy(() => import('./B2B'));
const FragileFrieght = lazy(() => import('./FragileFrieght'));
const SameDayDelivery = lazy(() => import('./SameDayDelivery'));
const Storage = lazy(() => import('./Storage'));
const LineHaulTruck = lazy(() => import('./LineHaulTruck'));
const Packing = lazy(() => import('./Packing'));
const MyMove = lazy(() => import('./MyMove'));
const Booking = lazy(() => import('./Booking'));
const About = lazy(() => import('./About'));
const Privacy = lazy(() => import('./Privacy'));
const WhyUs = lazy(() => import('./WhyUs'));
const NotFoundPage = lazy(() => import('./404'));
const Contact = lazy(() => import('./Contact'));
const Header = lazy(() => import('../../src/UI-Components/Common/Header'));

const Routing = () => {

    const location = useLocation();

    // Determine if the header should be shown
    const showHeader = location.pathname !== '/404notfound';

    return (<>
        <Suspense fallback={<Loader />}>
            {showHeader && <Header />}
            <Routes>
                <Route index element={<Index />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/house" element={<House />} />
                <Route path="/services/commercialdelivery" element={<CommercialDelivery />} />
                <Route path="/services/furniture" element={<Furniture />} />
                <Route path="/services/b2b" element={<B2B />} />
                <Route path="/services/fragilefreight" element={<FragileFrieght />} />
                <Route path="/services/samedaydelivery" element={<SameDayDelivery />} />
                <Route path="/services/storage" element={<Storage />} />
                <Route path="/services/linehaultruck" element={<LineHaulTruck />} />
                <Route path="/services/packing" element={<Packing />} />

                <Route path="/mymove" element={<MyMove />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/whyus" element={<WhyUs />} />

                <Route path="/booking" element={<Booking />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    </>);

};

export default Routing;