import "./UI-style/Global/Global.css"
import Routing from './UI-Components/Routing';
import { BrowserRouter } from "react-router-dom";

function App() {
    return (<>
        <BrowserRouter>
            <Routing />
        </BrowserRouter>
    </>)
}

export default App;